// 날짜 2024-10-10 형식 포매터
export const formatDate = (dateString: string) => {
    if (!dateString) return '날짜 정보 없음'
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

// 전화번호 포매터
export const formatPhoneNumber = (phone: string) => {
    if (!phone) return '정보 없음'
    return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
}
