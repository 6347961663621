import styled from '@emotion/styled'

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start; /* 항목들을 위쪽으로 정렬 */
    height: 200vh; /* 화면 높이를 2배로 설정 (테스트 용도) */
`
export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px; /* 아래쪽에 여백을 추가 */
`

export const ContantWrap = styled.div`
    width: 80%;
    height: 100%;
    margin-top: 2%;
`

export const Title = styled.h1`
    font-size: 23px;
    font-weight: 500;
    -webkit-background-clip: text;
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    letter-spacing: 2px;
    width: 440%;
`

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px; /* 테이블 모서리를 둥글게 처리 */
    background-color: #fafafa; /* 배경색을 부드럽게 설정 */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* 테이블에 부드러운 그림자 효과 */
    margin-top: 40px; /* 테이블과 캡션 사이의 간격 */
    position: relative; /* 캡션을 absolute로 위치시키기 위한 relative 설정 */
    margin: 6% 0;
`

// caption을 좌측 상단에 배치하고, 테이블과 겹치지 않게 10px 위로 설정
export const TableCaption = styled.caption`
    position: absolute;
    top: -35px; /* 테이블 위로 10px 떨어지게 설정 */
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
    color: #747474;
    border-radius: 5px;
`

// 기본 셀 스타일 (td와 th에 모두 적용)
export const Cell = styled.td`
    width: 35%;
    height: 60px;
    text-align: center;
    background-color: #fff; /* 셀 배경색을 하얀색으로 설정 */
    color: #000;
    font-size: 14px;
    line-height: 2;
    padding: 1% 0.5%;
`

export const CellInput = styled.td`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    height: 60px;
    text-align: center;
    background-color: #fff; /* 셀 배경색을 하얀색으로 설정 */
    color: #000;
    font-size: 14px;
    line-height: 2;
    padding: 1% 0.1%;
`

export const CellInput2 = styled.td`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 60px;
    text-align: center;
    background-color: #fff; /* 셀 배경색을 하얀색으로 설정 */
    color: #000;
    font-size: 14px;
    line-height: 2;
    padding: 1% 0.5%;
`

// 특별 셀 스타일 (1열, 3열에 적용)
export const SpecialCell = styled(Cell)`
    width: 14%;
    background-color: #f9f9f9; /* 특별 셀 배경색 */
    vertical-align: middle;
`

export const MapCell = styled(Cell)`
    width: 50%;
    background-color: #f9f9f9; /* 특별 셀 배경색 */
    vertical-align: middle;
`
export const BtnCell = styled(Cell)`
    text-align: start;
    padding-left: 10px;
`

export const ImgCell = styled(SpecialCell)`
    width: 6.5% !important;
    height: 200px !important;
`

// 업로드된 이미지들을 슬라이드로 관리할 컨테이너
export const ImageContainer = styled.div`
    display: inline-flex;
    gap: 10px;
    width: 100%;
    padding: 5px 0;
    max-width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
`

export const ImageWrapper = styled.div`
    display: inline-block;
    height: 100px;
    width: 100px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
`

export const ImagePreview = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const RemoveButton = styled.button`
    position: absolute;
    top: 5px;
    right: 5px;
    background: #ff0000;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const JosoPopWrap = styled.div`
    width: 100%;
`
