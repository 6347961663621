import Header from 'components/Elements/Header/Header'
import ARangeSelector from 'components/Elements/ARangeSelector/ARangeSelector'
import ACheckSelector from 'components/Elements/ACheckSelector/ACheckSelector'
import AButton from 'components/Elements/AButton/AButton'
import AModal from 'components/Elements/AModal/AModal'
import { useEffect, useState } from 'react'
import { useSession } from 'api/Session'
import { formatDate, formatPhoneNumber } from 'components/Comm/Formatter'
import { useAxios } from 'api/Axios'
import { DataType } from 'types/home.type'
import { CommCodesType } from 'types/commCodes.type'
import { useLocation } from 'react-router-dom'
import { SelectObjectType } from 'types/select'
import { useAlert } from 'components/Elements/Alert/Alert'
import { useNavigate } from 'react-router-dom'
import {
    CommDiv,
    Container,
    Sidebar,
    MapWrapper,
    Card,
    BtnDiv,
    ImageContainer,
    ImagePreview,
    ImageWrapper,
    PropertyInfoContainer,
    PropertyHeader,
    PropertyTitle,
    PropertyDetail,
    TradeTypeBadge,
    TypeBadge,
} from './Map.styles'

declare global {
    interface Window {
        kakao: any
    }
}

const Map = () => {
    const [properties, setProperties] = useState<any[]>([])
    const [map, setMap] = useState<any>(null)
    const [geocoder, setGeocoder] = useState<any>(null)
    const [locationAddr, setLocationAddr] = useState<string | null>(null)
    const [searchText, setSearchText] = useState('')
    const [filters, setFilters] = useState<Record<string, string[]>>({})
    const [filteredData, setFilteredData] = useState<DataType[]>([])
    const [serverFiles, setServerFiles] = useState<string[]>([])
    const [selectedProds, setSelectedProds] = useState<DataType[]>([])
    const [modalOpen, setModalOpen] = useState(false)
    const [rangeFilter, setRangeFilter] = useState({
        deposit: { min: 0, max: 10000 },
        monthlyRent: { min: 0, max: 200 },
    })
    const [codeItems, setCodeItems] = useState<
        Record<string, SelectObjectType[]>
    >({})

    const location = useLocation()
    const navigate = useNavigate()
    const { get, post } = useAxios()
    const { getSession } = useSession()

    const { open: successUpdate } = useAlert({
        icon: 'success',
        title: '알림',
        text: '최신일자 변경이 완료되었습니다.',
    })

    const { open: successDelete } = useAlert({
        icon: 'success',
        title: '알림',
        text: '삭제가 완료되었습니다.',
    })

    const mapCommCodeToSelectOptions = (
        codeList: CommCodesType[],
    ): SelectObjectType[] =>
        codeList.map(item => ({
            text: item.cdNm,
            value: item.dtlCd,
        }))

    const COMM_CODE_TYPES = [
        'PR001',
        'PR002',
        'PR003',
        'PR004',
        'PR005',
        'PR006',
        'PR007',
        'PR008',
    ] as const

    const COMM_CODE_MAP: Record<string, keyof DataType> = {
        PR001: 'type',
        PR002: 'tradeType',
        PR003: 'roomCd',
        PR004: 'bathCd',
        PR005: 'directionCd',
        PR006: 'moveInCd',
        PR007: 'rcmCd',
        PR008: 'remarkCd',
    }

    // 공통 함수로 빼둔 매물 조회 함수
    const selectProduct = async () => {
        const res = await get<DataType>('/api/product/products')

        if (res.valid && res.result) {
            setProperties(res.result.LIST)
        }
    }

    // 매물 관리 페이지에서 넘어온 주소를 처리하는 코드
    useEffect(() => {
        selectProduct()

        const item = location.state?.item

        if (item) {
            setLocationAddr(item)
        }
    }, [location])

    // 조회조건에 따른 필터 하는 코드
    useEffect(() => {
        const filteredData = properties.filter(item => {
            const deposit = item.deposit ? Number(item.deposit) : 0
            const rent = item.monthlyRent ? Number(item.monthlyRent) : 0

            const isWithinDepositRange =
                deposit >= rangeFilter.deposit.min &&
                deposit <= rangeFilter.deposit.max

            const isWithinRentRange =
                rent >= rangeFilter.monthlyRent.min &&
                rent <= rangeFilter.monthlyRent.max

            const matchesSearchText = searchText
                ? Object.values(item).some(value =>
                      value
                          ?.toString()
                          .toLowerCase()
                          .includes(searchText.toLowerCase()),
                  )
                : true

            return (
                Object.entries(filters).every(([key, selectedValues]) => {
                    const mappedKey = COMM_CODE_MAP[key]
                    const itemValue = item[mappedKey]

                    if (key === 'PR008') {
                        return (
                            selectedValues.length === 0 ||
                            selectedValues.some(value =>
                                itemValue.includes(value),
                            )
                        )
                    }
                    return (
                        selectedValues.length === 0 ||
                        selectedValues.includes(itemValue)
                    )
                }) &&
                isWithinDepositRange &&
                isWithinRentRange &&
                matchesSearchText
            )
        })

        setFilteredData(filteredData)
    }, [filters, rangeFilter, properties, searchText])

    // 맵검색 페이지가 렌더링 될 때 카카오맵을 만들어주는 코드
    useEffect(() => {
        const fetchCodeItems = async () => {
            const processedCodeItems = COMM_CODE_TYPES.reduce(
                (acc, codeType) => {
                    acc[codeType] = mapCommCodeToSelectOptions(
                        getSession('commCodes')[codeType].slice(1),
                    )
                    return acc
                },
                {} as Record<string, SelectObjectType[]>,
            )

            setCodeItems(processedCodeItems)
        }

        fetchCodeItems()

        if (!window.kakao || !window.kakao.maps) {
            return
        }

        const container = document.getElementById('map')
        const options = {
            center: new window.kakao.maps.LatLng(37.5665, 126.978),
            level: 3,
        }

        const newMap = new window.kakao.maps.Map(container, options)
        const newGeocoder = new window.kakao.maps.services.Geocoder()

        // 줌 컨트롤 추가
        const zoomControl = new window.kakao.maps.ZoomControl()
        newMap.addControl(zoomControl, window.kakao.maps.ControlPosition.RIGHT) // 오른쪽에 추가

        // 지도 타입 컨트롤 추가
        const mapTypeControl = new window.kakao.maps.MapTypeControl()
        newMap.addControl(
            mapTypeControl,
            window.kakao.maps.ControlPosition.TOPRIGHT,
        ) // 오른쪽 상단에 추가

        setMap(newMap)
        setGeocoder(newGeocoder)
    }, [])

    // 매물 리스트를 지도에 추가 해주는 로직
    useEffect(() => {
        if (properties.length > 0 && map && geocoder) {
            properties.forEach(property => {
                const {
                    prodAddr,
                    title,
                    depositAndMonthlyRent,
                    etc,
                    tradeType,
                } = property

                geocoder.addressSearch(prodAddr, (result: any, status: any) => {
                    if (status === window.kakao.maps.services.Status.OK) {
                        const coords = new window.kakao.maps.LatLng(
                            result[0].y,
                            result[0].x,
                        )

                        const marker = new window.kakao.maps.Marker({
                            map: map,
                            position: coords,
                        })

                        const tradeTypeText = (() => {
                            switch (tradeType) {
                                case '10':
                                    return '건물'
                                case '20':
                                    return '월세'
                                case '30':
                                    return '전세'
                                case '40':
                                    return '반전세'
                                case '50':
                                    return '매매'
                                case '60':
                                    return '임대'
                                default:
                                    return '알 수 없음'
                            }
                        })()

                        const infoContent = `
                            <div style="
                                padding: 12px;
                                
                                
                                font-size: 14px;
                                line-height: 1.6;
                                min-height: 100px;
                            ">
                                <strong style="display: block; font-weight: bold; margin-bottom: 8px;">
                                    <span style="color: #e74c3c; font-weight: normal;">
                                    [${tradeTypeText}]
                                    </span> ${title}
                                </strong>
                                <span style="display: block; color: #555;">
                                    ${depositAndMonthlyRent || '정보 없음'}
                                </span>
                                <span style="display: block; color: #555;">
                                    ${etc}
                                </span>
                            </div>
                        `

                        const infoWindow = new window.kakao.maps.InfoWindow({
                            content: infoContent,
                        })
                        infoWindow.open(map, marker)
                    }
                })
            })

            // 첫 번째 매물 주소로 지도 중심을 설정
            const firstPropertyAddr = properties[0].prodAddr
            if (!locationAddr) {
                geocoder.addressSearch(
                    firstPropertyAddr,
                    (result: any, status: any) => {
                        if (status === window.kakao.maps.services.Status.OK) {
                            const coords = new window.kakao.maps.LatLng(
                                result[0].y,
                                result[0].x,
                            )
                            map.setCenter(coords)
                        }
                    },
                )
            }
        }
    }, [properties, map, geocoder, locationAddr])

    // 매물관리 페이지에서 넘어 왔을 때 넘어온 주소로 맵을 이동 시켜주는 코드
    // 현재는 setTimeout으로 구현 해뒀는데 추후 개선 해야함
    useEffect(() => {
        if (map && geocoder) {
            if (locationAddr) {
                setTimeout(() => {
                    geocoder.addressSearch(
                        locationAddr,
                        (result: any, status: any) => {
                            if (
                                status === window.kakao.maps.services.Status.OK
                            ) {
                                const coords = new window.kakao.maps.LatLng(
                                    result[0].y,
                                    result[0].x,
                                )
                                map.setCenter(coords)
                            }
                        },
                    )
                }, 200)
            }
        }
    }, [locationAddr])

    // 사이드바에서 매물 클릭 했을 때 해당 매물의 주소로 지도 이동 시켜주는 코드
    const handlePropertyClick = async (property: DataType[]) => {
        const response = await get<string>('/api/product/getImgsrcByProdNo', [
            { key: 'prodNo', value: property[0].prodNo },
        ])

        if (response.valid && response.result) {
            const images: string[] = response.result.LIST
            setServerFiles(images)
            setSelectedProds(property)
            setModalOpen(true)
        }

        if (map && geocoder) {
            geocoder.addressSearch(
                property[0].prodAddr,
                (result: any, status: any) => {
                    if (status === window.kakao.maps.services.Status.OK) {
                        const coords = new window.kakao.maps.LatLng(
                            result[0].y,
                            result[0].x,
                        )
                        map.setCenter(coords)
                    }
                },
            )
        }
    }

    // 헤더의 input의 value값을 추출하는 코드
    const handleInputChange = (value: string) => {
        setSearchText(value)
    }

    // 보증금, 월세의 값을 추출하는 코드
    const handleRangeChange = (
        type: 'deposit' | 'monthlyRent',
        range: { min: number; max: number },
    ) => {
        setRangeFilter(prev => ({
            ...prev,
            [type]: range,
        }))
    }

    // 보증금, 월세를 제외 한 필터 조건의 값을 추출하는 코드
    const handleChangeSelector = (key: string, selectedItems: string[]) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [key]: selectedItems,
        }))
    }

    // 매물 수정
    const handleCrrection = (row: DataType) => {
        const itemToUpdate = { ...row, chgResn: 'U' }

        switch (row.type) {
            case '10':
            case '20':
            case '30':
            case '40':
                navigate('/product/registration', {
                    state: { item: itemToUpdate },
                })
                break
            case '50':
                navigate('/bargain/registration', {
                    state: { item: itemToUpdate },
                })
                break
            case '60':
                navigate('/rent/registration', {
                    state: { item: itemToUpdate },
                })
                break
        }
    }

    // 매물 카피
    const handleCopy = (row: DataType) => {
        const itemToCopy = { ...row, chgResn: 'C' }

        switch (row.type) {
            case '10':
            case '20':
            case '30':
            case '40':
                navigate('/product/registration', {
                    state: { item: itemToCopy },
                })
                break
            case '50':
                navigate('/bargain/registration', {
                    state: { item: itemToCopy },
                })
                break
            case '60':
                navigate('/rent/registration', {
                    state: { item: itemToCopy },
                })
                break
        }
    }

    // 매물 최신일자 업데이트
    const handleUpdateNewDtm = async (row: DataType) => {
        const response = await post<void>('/api/product/updateNewDtm', {
            prodNo: row.prodNo,
            userNo: getSession('userNo'),
        })

        if (response.valid && response.result) {
            successUpdate(() => {
                selectProduct()
            })
        }
    }

    // 매물 삭제
    const handleDeleteProd = async (row: DataType) => {
        const response = await post<void>('/api/product/deleteProduct', {
            prodNo: row.prodNo,
        })

        if (response.valid && response.result) {
            successDelete(() => {
                selectProduct()
            })
        }
    }

    const handleCloseModal = (close: boolean) => {
        setModalOpen(close)
    }

    const tradeTypeStyle = {
        color: '#e74c3c',
        fontWeight: 'normal',
    }

    const typeStyle = {
        color: '#0066ff',
        fontWeight: 'normal',
    }

    return (
        <>
            <Header handleInputChange={handleInputChange} />
            <CommDiv>
                <ARangeSelector
                    onChange={range => handleRangeChange('deposit', range)}
                    min={0}
                    max={10000}
                    placeholder="보증금"
                    mr="10px"
                />
                <ARangeSelector
                    onChange={range => handleRangeChange('monthlyRent', range)}
                    min={0}
                    max={200}
                    placeholder="월세"
                    mr="10px"
                />
                {COMM_CODE_TYPES.map((codeType, index) => {
                    const placeholderMap = {
                        PR001: '거래종류',
                        PR002: '거래방식',
                        PR003: '방수',
                        PR004: '욕실수',
                        PR005: '방향',
                        PR006: '입주일',
                        PR007: '상태',
                        PR008: '추가옵션',
                    }

                    return (
                        <ACheckSelector
                            key={codeType}
                            options={codeItems[codeType] || []}
                            placeholder={placeholderMap[codeType]}
                            onChange={selectedItems =>
                                handleChangeSelector(codeType, selectedItems)
                            } // Key 전달
                            mr="10px"
                        />
                    )
                })}
            </CommDiv>
            <Container>
                <Sidebar>
                    <h2
                        style={{
                            fontSize: '20px',
                            fontWeight: '600',
                            color: '#333',
                            marginBottom: '20px',
                            paddingBottom: '10px',
                        }}
                    >
                        매물 리스트
                    </h2>
                    {filteredData.map((property, index) => (
                        <Card
                            key={index}
                            onClick={() => handlePropertyClick(Array(property))}
                        >
                            <div>
                                <h3
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {property.tradeType === '10' && (
                                        <span style={tradeTypeStyle}>
                                            [건물]{' '}
                                        </span>
                                    )}
                                    {property.tradeType === '20' && (
                                        <span style={tradeTypeStyle}>
                                            [월세]{' '}
                                        </span>
                                    )}
                                    {property.tradeType === '30' && (
                                        <span style={tradeTypeStyle}>
                                            [전세]{' '}
                                        </span>
                                    )}
                                    {property.tradeType === '40' && (
                                        <span style={tradeTypeStyle}>
                                            [반전세]{' '}
                                        </span>
                                    )}
                                    {property.tradeType === '50' && (
                                        <span style={tradeTypeStyle}>
                                            [매매]{' '}
                                        </span>
                                    )}
                                    {property.tradeType === '60' && (
                                        <span style={tradeTypeStyle}>
                                            [임대]{' '}
                                        </span>
                                    )}

                                    {property.type === '10' && (
                                        <div style={typeStyle}>[원룸]</div>
                                    )}
                                    {property.type === '20' && (
                                        <div style={typeStyle}>[오피스텔]</div>
                                    )}
                                    {property.type === '30' && (
                                        <div style={typeStyle}>[아파트]</div>
                                    )}
                                    {property.type === '40' && (
                                        <div style={typeStyle}>[빌라]</div>
                                    )}
                                    {property.type === '50' && (
                                        <div style={typeStyle}>[주택]</div>
                                    )}
                                    {property.type === '60' && (
                                        <div style={typeStyle}>[상가]</div>
                                    )}
                                    {property.type === '70' && (
                                        <div style={typeStyle}>[토지]</div>
                                    )}
                                </h3>
                                <h3>{property.title}</h3>
                            </div>

                            <p className="highlight">
                                {property.tradeType === '10' ||
                                property.tradeType === '20' ||
                                property.tradeType === '30' ||
                                property.tradeType === '40'
                                    ? property.depositAndMonthlyRent ||
                                      '정보 없음'
                                    : property.tradeType === '50'
                                    ? property.salePricdAndDepoAndRent ||
                                      '정보 없음'
                                    : property.tradeType === '60'
                                    ? property.depositAndMonthlyRentAndPreFee ||
                                      '정보 없음'
                                    : '정보 없음'}
                            </p>
                            <p>
                                현관: {property.unitPwd} / 세대:{' '}
                                {property.entrancePwd}
                            </p>
                            <p>주소: {property.prodAddr}</p>
                            <p>
                                번호: {formatPhoneNumber(property.phoneNo1)} /{' '}
                                {formatPhoneNumber(property.phoneNo2)}
                            </p>
                            <p>
                                기타정보:{' '}
                                {property.etc ? property.etc : '정보 없음'}
                            </p>
                            <p>{formatDate(property.newDtm)}</p>

                            <BtnDiv>
                                <AButton
                                    onClick={event => {
                                        event.stopPropagation()
                                        handleCrrection(property)
                                    }}
                                    width="50px"
                                    mr="8px"
                                >
                                    수정
                                </AButton>

                                <AButton
                                    onClick={event => {
                                        event.stopPropagation()
                                        handleCopy(property)
                                    }}
                                    width="50px"
                                    mr="8px"
                                >
                                    복사
                                </AButton>
                                <AButton
                                    onClick={event => {
                                        event.stopPropagation()
                                        handleUpdateNewDtm(property)
                                    }}
                                    width="50px"
                                    mr="8px"
                                >
                                    UP
                                </AButton>
                                <AButton
                                    onClick={event => {
                                        event.stopPropagation()
                                        handleDeleteProd(property)
                                    }}
                                    width="50px"
                                >
                                    삭제
                                </AButton>
                            </BtnDiv>
                        </Card>
                    ))}
                </Sidebar>

                <MapWrapper id="map"></MapWrapper>
            </Container>
            <AModal
                showSaveBtn
                modalH="95%"
                title="매물 상세"
                value={modalOpen}
                onClose={handleCloseModal}
            >
                {selectedProds.map((property, index) => (
                    <PropertyInfoContainer key={index}>
                        <PropertyHeader>
                            {/* tradeType 조건 */}
                            {property.tradeType === '10' && (
                                <TradeTypeBadge>[건물]</TradeTypeBadge>
                            )}
                            {property.tradeType === '20' && (
                                <TradeTypeBadge>[월세]</TradeTypeBadge>
                            )}
                            {property.tradeType === '30' && (
                                <TradeTypeBadge>[전세]</TradeTypeBadge>
                            )}
                            {property.tradeType === '40' && (
                                <TradeTypeBadge>[반전세]</TradeTypeBadge>
                            )}
                            {property.tradeType === '50' && (
                                <TradeTypeBadge>[매매]</TradeTypeBadge>
                            )}
                            {property.tradeType === '60' && (
                                <TradeTypeBadge>[임대]</TradeTypeBadge>
                            )}

                            {/* type 조건 */}
                            {property.type === '10' && (
                                <TypeBadge>[원룸]</TypeBadge>
                            )}
                            {property.type === '20' && (
                                <TypeBadge>[오피스텔]</TypeBadge>
                            )}
                            {property.type === '30' && (
                                <TypeBadge>[아파트]</TypeBadge>
                            )}
                            {property.type === '40' && (
                                <TypeBadge>[빌라]</TypeBadge>
                            )}
                            {property.type === '50' && (
                                <TypeBadge>[주택]</TypeBadge>
                            )}
                            {property.type === '60' && (
                                <TypeBadge>[상가]</TypeBadge>
                            )}
                            {property.type === '70' && (
                                <TypeBadge>[토지]</TypeBadge>
                            )}
                        </PropertyHeader>

                        <PropertyTitle>{property.title}</PropertyTitle>

                        <PropertyDetail>
                            <strong>가격: </strong>
                            {property.tradeType === '10' ||
                            property.tradeType === '20' ||
                            property.tradeType === '30' ||
                            property.tradeType === '40'
                                ? property.depositAndMonthlyRent || '정보 없음'
                                : property.tradeType === '50'
                                ? property.salePricdAndDepoAndRent ||
                                  '정보 없음'
                                : property.tradeType === '60'
                                ? property.depositAndMonthlyRentAndPreFee ||
                                  '정보 없음'
                                : '정보 없음'}
                        </PropertyDetail>

                        <PropertyDetail>
                            <strong>현관:</strong> {property.unitPwd} /{' '}
                            <strong>세대:</strong> {property.entrancePwd}
                        </PropertyDetail>

                        <PropertyDetail>
                            <strong>주소:</strong> {property.prodAddr}
                        </PropertyDetail>

                        <PropertyDetail>
                            <strong>번호:</strong>{' '}
                            {formatPhoneNumber(property.phoneNo1)} /{' '}
                            {formatPhoneNumber(property.phoneNo2)}
                        </PropertyDetail>

                        <PropertyDetail>
                            <strong>기타정보:</strong>{' '}
                            {property.etc ? property.etc : '정보 없음'}
                        </PropertyDetail>

                        <PropertyDetail>
                            {formatDate(property.newDtm)}
                        </PropertyDetail>
                    </PropertyInfoContainer>
                ))}

                <ImageContainer>
                    {serverFiles.map((filePath, index) => (
                        <ImageWrapper key={`server-${index}`}>
                            <ImagePreview
                                src={filePath}
                                alt={`Server File ${index}`}
                            />
                        </ImageWrapper>
                    ))}
                </ImageContainer>
            </AModal>
        </>
    )
}

export default Map
