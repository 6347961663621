export const AG_GRID_LOCALE_KO = {
    // Set Filter
    selectAll: '(전체 선택)',
    selectAllSearchResults: '(검색 결과 전체 선택)',
    addCurrentSelectionToFilter: '현재 선택 항목을 필터에 추가',
    searchOoo: '검색...',
    blanks: '(빈 항목)',
    noMatches: '일치하는 항목 없음',

    // Number Filter & Text Filter
    filterOoo: '필터...',
    equals: '같음',
    notEqual: '같지 않음',
    blank: '빈 칸',
    notBlank: '빈 칸이 아님',
    empty: '선택해 주세요',

    // Number Filter
    lessThan: '보다 작음',
    greaterThan: '보다 큼',
    lessThanOrEqual: '이하',
    greaterThanOrEqual: '이상',
    inRange: '범위 내',
    inRangeStart: '시작',
    inRangeEnd: '종료',

    // Text Filter
    contains: '포함',
    notContains: '포함하지 않음',
    startsWith: '시작 문자',
    endsWith: '끝 문자',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',
    before: '이전',
    after: '이후',

    // Filter Conditions
    andCondition: '그리고',
    orCondition: '또는',

    // Filter Buttons
    applyFilter: '적용',
    resetFilter: '재설정',
    clearFilter: '필터 지우기',
    cancelFilter: '취소',

    // Filter Titles
    textFilter: '텍스트 필터',
    numberFilter: '숫자 필터',
    dateFilter: '날짜 필터',
    setFilter: '세트 필터',

    // Group Column Filter
    groupFilterSelect: '필드 선택:',

    // Advanced Filter
    advancedFilterContains: '포함',
    advancedFilterNotContains: '포함하지 않음',
    advancedFilterTextEquals: '같음',
    advancedFilterTextNotEqual: '같지 않음',
    advancedFilterStartsWith: '시작 문자',
    advancedFilterEndsWith: '끝 문자',
    advancedFilterBlank: '빈 칸',
    advancedFilterNotBlank: '빈 칸이 아님',
    advancedFilterEquals: '=',
    advancedFilterNotEqual: '!=',
    advancedFilterGreaterThan: '>',
    advancedFilterGreaterThanOrEqual: '>=',
    advancedFilterLessThan: '<',
    advancedFilterLessThanOrEqual: '<=',
    advancedFilterTrue: '참',
    advancedFilterFalse: '거짓',
    advancedFilterAnd: '그리고',
    advancedFilterOr: '또는',
    advancedFilterApply: '적용',
    advancedFilterBuilder: '필터 생성기',
    advancedFilterValidationMissingColumn: '열이 없습니다',
    advancedFilterValidationMissingOption: '옵션이 없습니다',
    advancedFilterValidationMissingValue: '값이 없습니다',
    advancedFilterValidationInvalidColumn: '열을 찾을 수 없습니다',
    advancedFilterValidationInvalidOption: '옵션을 찾을 수 없습니다',
    advancedFilterValidationMissingQuote: '값의 끝 따옴표가 없습니다',
    advancedFilterValidationNotANumber: '값이 숫자가 아닙니다',
    advancedFilterValidationInvalidDate: '값이 유효한 날짜가 아닙니다',
    advancedFilterValidationMissingCondition: '조건이 없습니다',
    advancedFilterValidationJoinOperatorMismatch:
        '조건 내의 조인 연산자는 동일해야 합니다',
    advancedFilterValidationInvalidJoinOperator:
        '조인 연산자를 찾을 수 없습니다',
    advancedFilterValidationMissingEndBracket: '끝 괄호가 없습니다',
    advancedFilterValidationExtraEndBracket: '끝 괄호가 너무 많습니다',
    advancedFilterValidationMessage:
        '표현식에 오류가 있습니다. ${variable} - ${variable}.',
    advancedFilterValidationMessageAtEnd:
        '표현식에 오류가 있습니다. ${variable}가 표현식 끝에 있습니다.',
    advancedFilterBuilderTitle: '고급 필터',
    advancedFilterBuilderApply: '적용',
    advancedFilterBuilderCancel: '취소',
    advancedFilterBuilderAddButtonTooltip: '필터 또는 그룹 추가',
    advancedFilterBuilderRemoveButtonTooltip: '제거',
    advancedFilterBuilderMoveUpButtonTooltip: '위로 이동',
    advancedFilterBuilderMoveDownButtonTooltip: '아래로 이동',
    advancedFilterBuilderAddJoin: '그룹 추가',
    advancedFilterBuilderAddCondition: '필터 추가',
    advancedFilterBuilderSelectColumn: '열 선택',
    advancedFilterBuilderSelectOption: '옵션 선택',
    advancedFilterBuilderEnterValue: '값 입력...',
    advancedFilterBuilderValidationAlreadyApplied:
        '현재 필터가 이미 적용되었습니다.',
    advancedFilterBuilderValidationIncomplete:
        '모든 조건이 완료되지 않았습니다.',
    advancedFilterBuilderValidationSelectColumn: '열을 선택해야 합니다.',
    advancedFilterBuilderValidationSelectOption: '옵션을 선택해야 합니다.',
    advancedFilterBuilderValidationEnterValue: '값을 입력해야 합니다.',

    // Side Bar
    columns: '열',
    filters: '필터',

    // columns tool panel
    pivotMode: '피벗 모드',
    groups: '행 그룹',
    rowGroupColumnsEmptyMessage: '여기로 끌어서 행 그룹 설정',
    values: '값',
    valueColumnsEmptyMessage: '여기로 끌어서 집계',
    pivots: '열 레이블',
    pivotColumnsEmptyMessage: '여기로 끌어서 열 레이블 설정',

    // Header of the Default Group Column
    group: '그룹',

    // Row Drag
    rowDragRow: '행',
    rowDragRows: '행',

    // Other
    loadingOoo: '로드 중...',
    loadingError: '오류',
    noRowsToShow: '데이터가 없습니다.',
    enabled: '사용',

    // Menu
    pinColumn: '열 고정',
    pinLeft: '왼쪽에 고정',
    pinRight: '오른쪽에 고정',
    noPin: '고정 안함',
    valueAggregation: '값 집계',
    noAggregation: '없음',
    autosizeThiscolumn: '이 열 자동 크기 조정',
    autosizeAllColumns: '모든 열 자동 크기 조정',
    groupBy: '그룹 기준',
    ungroupBy: '그룹 해제',
    ungroupAll: '모두 그룹 해제',
    addToValues: '값에 ${variable} 추가',
    removeFromValues: '값에서 ${variable} 제거',
    addToLabels: '레이블에 ${variable} 추가',
    removeFromLabels: '레이블에서 ${variable} 제거',
    resetColumns: '열 재설정',
    expandAll: '모든 행 그룹 확장',
    collapseAll: '모든 행 그룹 축소',
    copy: '복사',
    ctrlC: 'Ctrl+C',
    ctrlX: 'Ctrl+X',
    copyWithHeaders: '헤더 포함 복사',
    copyWithGroupHeaders: '그룹 헤더 포함 복사',
    cut: '잘라내기',
    paste: '붙여넣기',
    ctrlV: 'Ctrl+V',
    export: '내보내기',
    csvExport: 'CSV 내보내기',
    excelExport: 'Excel 내보내기',
    columnFilter: '열 필터',
    columnChooser: '열 선택',
    sortAscending: '오름차순 정렬',
    sortDescending: '내림차순 정렬',
    sortUnSort: '정렬 취소',

    // Enterprise Menu Aggregation and Status Bar
    sum: '합계',
    first: '첫 번째',
    last: '마지막',
    min: '최소',
    max: '최대',
    none: '없음',
    count: '개수',
    avg: '평균',
    filteredRows: '필터된 행',
    selectedRows: '선택된 행',
    totalRows: '전체 행',
    totalAndFilteredRows: '행',
    more: '더보기',
    to: '건 부터',
    of: '건 까지',
    page: '페이지',
    pageLastRowUnknown: '?',
    nextPage: '다음 페이지',
    lastPage: '마지막 페이지',
    firstPage: '첫 페이지',
    previousPage: '이전 페이지',
    pageSizeSelectorLabel: '전체 조회 건수:',
    footerTotal: '총합',

    // Pivoting
    pivotColumnGroupTotals: '총합',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: '피벗 차트 및 피벗 모드',
    pivotChart: '피벗 차트',
    chartRange: '차트 범위',

    columnChart: '세로 막대형',
    groupedColumn: '그룹형',
    stackedColumn: '스택형',
    normalizedColumn: '100% 스택형',

    barChart: '가로 막대형',
    groupedBar: '그룹형',
    stackedBar: '스택형',
    normalizedBar: '100% 스택형',

    pieChart: '원형 차트',
    pie: '원형',
    donut: '도넛형',

    line: '선형',

    xyChart: 'X Y (산점도)',
    scatter: '산점도',
    bubble: '버블',

    areaChart: '영역 차트',
    area: '영역',
    stackedArea: '스택형',
    normalizedArea: '100% 스택형',

    histogramChart: '히스토그램',
    histogramFrequency: '빈도',

    polarChart: '극 좌표 차트',
    radarLine: '레이다 선형',
    radarArea: '레이다 영역형',
    nightingale: '나이팅게일',
    radialColumn: '반지름 막대형',
    radialBar: '반지름 가로 막대형',

    statisticalChart: '통계 차트',
    boxPlot: '상자 그림',
    rangeBar: '범위 가로 막대형',
    rangeArea: '범위 영역형',

    hierarchicalChart: '계층형 차트',
    treemap: '트리맵',
    sunburst: '선버스트',

    specializedChart: '특수 차트',
    waterfall: '폭포',
    heatmap: '히트맵',

    combinationChart: '복합 차트',
    columnLineCombo: '세로 막대형 및 선형',
    AreaColumnCombo: '영역형 및 세로 막대형',

    // Charts
    pivotChartTitle: '피벗 차트',
    rangeChartTitle: '범위 차트',
    settings: '차트 설정',
    data: '데이터',
    format: '형식',
    categories: '카테고리',
    defaultCategory: '(없음)',
    series: '시리즈',
    switchCategorySeries: '카테고리 / 시리즈 전환',
    categoryValues: '카테고리 값',
    seriesLabels: '시리즈 라벨',
    aggregate: '집계',
    xyValues: 'X Y 값',
    paired: '페어 모드',
    axis: '축',
    xAxis: '가로 축',
    yAxis: '세로 축',
    polarAxis: '극 좌표 축',
    radiusAxis: '반지름 축',
    navigator: '내비게이터',
    zoom: '확대/축소',
    animation: '애니메이션',
    crosshair: '교차선',
    color: '색상',
    thickness: '두께',
    preferredLength: '선호 길이',
    xType: 'X 유형',
    axisType: '축 유형',
    automatic: '자동',
    category: '카테고리',
    number: '숫자',
    time: '시간',
    timeFormat: '시간 형식',
    autoRotate: '자동 회전',
    labelRotation: '라벨 회전',
    circle: '원',
    polygon: '다각형',
    orientation: '방향',
    fixed: '고정',
    parallel: '평행',
    perpendicular: '수직',
    radiusAxisPosition: '위치',
    ticks: '틱',
    gridLines: '격자선',
    width: '너비',
    height: '높이',
    length: '길이',
    padding: '패딩',
    spacing: '간격',
    chart: '차트',
    title: '제목',
    titlePlaceholder: '차트 제목 - 더블 클릭하여 편집',
    background: '배경',
    font: '글꼴',
    top: '위쪽',
    right: '오른쪽',
    bottom: '아래쪽',
    left: '왼쪽',
    labels: '라벨',
    calloutLabels: '콜아웃 라벨',
    sectorLabels: '부문 라벨',
    positionRatio: '위치 비율',
    size: '크기',
    shape: '모양',
    minSize: '최소 크기',
    maxSize: '최대 크기',
    legend: '범례',
    position: '위치',
    markerSize: '마커 크기',
    markerStroke: '마커 스트로크',
    markerPadding: '마커 패딩',
    itemSpacing: '항목 간격',
    itemPaddingX: '항목 패딩 X',
    itemPaddingY: '항목 패딩 Y',
    layoutHorizontalSpacing: '수평 간격',
    layoutVerticalSpacing: '수직 간격',
    strokeWidth: '선 두께',
    offset: '오프셋',
    offsets: '오프셋',
    tooltips: '툴팁',
    callout: '콜아웃',
    markers: '마커',
    shadow: '그림자',
    blur: '블러',
    xOffset: 'X 오프셋',
    yOffset: 'Y 오프셋',
    lineWidth: '선 두께',
    lineDash: '선 대시',
    lineDashOffset: '대시 오프셋',
    scrollingZoom: '스크롤 확대/축소',
    scrollingStep: '스크롤 단계',
    selectingZoom: '선택 확대/축소',
    durationMillis: '기간 (밀리초)',
    crosshairLabel: '라벨',
    crosshairSnap: '노드에 맞추기',
    normal: '보통',
    bold: '굵게',
    italic: '이탤릭체',
    boldItalic: '굵은 이탤릭체',
    predefined: '미리 정의된',
    fillOpacity: '채우기 불투명도',
    strokeColor: '선 색상',
    strokeOpacity: '선 불투명도',
    miniChart: '미니 차트',
    histogramBinCount: '빈 수',
    connectorLine: '연결 선',
    seriesItems: '시리즈 항목',
    seriesItemType: '항목 유형',
    seriesItemPositive: '양수',
    seriesItemNegative: '음수',
    seriesItemLabels: '항목 라벨',
    columnGroup: '세로 막대형',
    barGroup: '가로 막대형',
    pieGroup: '원형',
    lineGroup: '선형',
    scatterGroup: 'X Y (산점도)',
    areaGroup: '영역형',
    polarGroup: '극 좌표형',
    statisticalGroup: '통계형',
    hierarchicalGroup: '계층형',
    specializedGroup: '특수형',
    combinationGroup: '복합형',
    groupedColumnTooltip: '그룹형',
    stackedColumnTooltip: '스택형',
    normalizedColumnTooltip: '100% 스택형',
    groupedBarTooltip: '그룹형',
    stackedBarTooltip: '스택형',
    normalizedBarTooltip: '100% 스택형',
    pieTooltip: '원형',
    donutTooltip: '도넛형',
    lineTooltip: '선형',
    groupedAreaTooltip: '영역형',
    stackedAreaTooltip: '스택형',
    normalizedAreaTooltip: '100% 스택형',
    scatterTooltip: '산점도',
    bubbleTooltip: '버블',
    histogramTooltip: '히스토그램',
    radialColumnTooltip: '반지름 세로 막대형',
    radialBarTooltip: '반지름 가로 막대형',
    radarLineTooltip: '레이다 선형',
    radarAreaTooltip: '레이다 영역형',
    nightingaleTooltip: '나이팅게일',
    rangeBarTooltip: '범위 가로 막대형',
    rangeAreaTooltip: '범위 영역형',
    boxPlotTooltip: '상자 그림',
    treemapTooltip: '트리맵',
    sunburstTooltip: '선버스트',
    waterfallTooltip: '폭포',
    heatmapTooltip: '히트맵',
    columnLineComboTooltip: '세로 막대형 및 선형',
    areaColumnComboTooltip: '영역형 및 세로 막대형',
    customComboTooltip: '사용자 정의 조합',
    innerRadius: '내부 반경',
    startAngle: '시작 각도',
    endAngle: '종료 각도',
    reverseDirection: '방향 반전',
    groupPadding: '그룹 패딩',
    seriesPadding: '시리즈 패딩',
    tile: '타일',
    whisker: '수염',
    cap: '캡',
    capLengthRatio: '길이 비율',
    labelPlacement: '라벨 배치',
    inside: '안쪽',
    outside: '바깥쪽',
    noDataToChart: '차트에 사용할 데이터가 없습니다.',
    pivotChartRequiresPivotMode: '피벗 차트는 피벗 모드가 활성화되어야 합니다.',
    chartSettingsToolbarTooltip: '메뉴',
    chartLinkToolbarTooltip: '그리드에 연결됨',
    chartUnlinkToolbarTooltip: '그리드에서 분리됨',
    chartDownloadToolbarTooltip: '차트 다운로드',
    chartEdit: '차트 편집',
    chartAdvancedSettings: '고급 설정',
    chartLink: '그리드에 연결',
    chartUnlink: '그리드에서 분리',
    chartDownload: '차트 다운로드',
    seriesChartType: '시리즈 차트 유형',
    seriesType: '시리즈 유형',
    secondaryAxis: '보조 축',
    seriesAdd: '시리즈 추가',
    categoryAdd: '카테고리 추가',
    advancedSettings: '고급 설정',
    direction: '방향',
    horizontal: '수평',
    vertical: '수직',
    seriesGroupType: '그룹 유형',
    groupedSeriesGroupType: '그룹형',
    stackedSeriesGroupType: '스택형',
    normalizedSeriesGroupType: '100% 스택형',

    // ARIA
    ariaAdvancedFilterBuilderItem:
        '${variable}. 레벨 ${variable}. 편집하려면 ENTER 키를 누르세요.',
    ariaAdvancedFilterBuilderItemValidation:
        '${variable}. 레벨 ${variable}. ${variable} 편집하려면 ENTER 키를 누르세요.',
    ariaAdvancedFilterBuilderList: '고급 필터 생성기 목록',
    ariaAdvancedFilterBuilderFilterItem: '필터 조건',
    ariaAdvancedFilterBuilderGroupItem: '필터 그룹',
    ariaAdvancedFilterBuilderColumn: '열',
    ariaAdvancedFilterBuilderOption: '옵션',
    ariaAdvancedFilterBuilderValueP: '값',
    ariaAdvancedFilterBuilderJoinOperator: '조인 연산자',
    ariaAdvancedFilterInput: '고급 필터 입력',
    ariaChecked: '선택됨',
    ariaColumn: '열',
    ariaColumnGroup: '열 그룹',
    ariaColumnFiltered: '열 필터됨',
    ariaColumnSelectAll: '모든 열 선택 전환',
    ariaDateFilterInput: '날짜 필터 입력',
    ariaDefaultListName: '목록',
    ariaFilterColumnsInput: '열 필터 입력',
    ariaFilterFromValue: '값에서 필터',
    ariaFilterInput: '필터 입력',
    ariaFilterList: '필터 목록',
    ariaFilterToValue: '값으로 필터',
    ariaFilterValue: '필터 값',
    ariaFilterMenuOpen: '필터 메뉴 열기',
    ariaFilteringOperator: '필터링 연산자',
    ariaHidden: '숨김',
    ariaIndeterminate: '불확정',
    ariaInputEditor: '입력 편집기',
    ariaMenuColumn: '열 메뉴 열기',
    ariaFilterColumn: '필터 열기',
    ariaRowDeselect: '이 행 선택 해제하려면 SPACE 키를 누르세요',
    ariaRowSelectAll: '모든 행 선택 전환하려면 SPACE 키를 누르세요',
    ariaRowToggleSelection: '행 선택 전환하려면 SPACE 키를 누르세요',
    ariaRowSelect: '이 행 선택하려면 SPACE 키를 누르세요',
    ariaRowSelectionDisabled: '이 행에 대한 선택이 비활성화됨',
    ariaSearch: '검색',
    ariaSortableColumn: '정렬하려면 ENTER 키를 누르세요',
    ariaToggleVisibility: '가시성 전환하려면 SPACE 키를 누르세요',
    ariaToggleCellValue: '셀 값 전환하려면 SPACE 키를 누르세요',
    ariaUnchecked: '선택 안됨',
    ariaVisible: '보임',
    ariaSearchFilterValues: '검색 필터 값',
    ariaPageSizeSelectorLabel: '페이지 크기',
    ariaChartMenuClose: '차트 편집 메뉴 닫기',
    ariaSkeletonCellLoadingFailed: '행 로드 실패',
    ariaSkeletonCellLoading: '행 데이터 로드 중',

    // ARIA Labels for Drop Zones
    ariaRowGroupDropZonePanelLabel: '행 그룹',
    ariaValuesDropZonePanelLabel: '값',
    ariaPivotDropZonePanelLabel: '열 레이블',
    ariaDropZoneColumnComponentDescription: '제거하려면 DELETE 키를 누르세요',
    ariaDropZoneColumnValueItemDescription:
        '집계 유형을 변경하려면 ENTER 키를 누르세요',
    ariaDropZoneColumnGroupItemDescription: '정렬하려면 ENTER 키를 누르세요',
    // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
    ariaDropZoneColumnComponentAggFuncSeparator: '의 ',
    ariaDropZoneColumnComponentSortAscending: '오름차순',
    ariaDropZoneColumnComponentSortDescending: '내림차순',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: '열 메뉴',
    ariaLabelColumnFilter: '열 필터',
    ariaLabelCellEditor: '셀 편집기',
    ariaLabelDialog: '대화상자',
    ariaLabelSelectField: '필드 선택',
    ariaLabelRichSelectField: '리치 선택 필드',
    ariaLabelTooltip: '툴팁',
    ariaLabelContextMenu: '컨텍스트 메뉴',
    ariaLabelSubMenu: '서브메뉴',
    ariaLabelAggregationFunction: '집계 함수',
    ariaLabelAdvancedFilterAutocomplete: '고급 필터 자동 완성',
    ariaLabelAdvancedFilterBuilderAddField: '고급 필터 생성기 필드 추가',
    ariaLabelAdvancedFilterBuilderColumnSelectField:
        '고급 필터 생성기 열 선택 필드',
    ariaLabelAdvancedFilterBuilderOptionSelectField:
        '고급 필터 생성기 옵션 선택 필드',
    ariaLabelAdvancedFilterBuilderJoinSelectField:
        '고급 필터 생성기 조인 연산자 선택 필드',

    // ARIA Labels for the Side Bar
    ariaColumnPanelList: '열 목록',
    ariaFilterPanelList: '필터 목록',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: ',',
    decimalSeparator: '.',

    // Data types
    true: '참',
    false: '거짓',
    invalidDate: '유효하지 않은 날짜',
    invalidNumber: '유효하지 않은 숫자',
    january: '1월',
    february: '2월',
    march: '3월',
    april: '4월',
    may: '5월',
    june: '6월',
    july: '7월',
    august: '8월',
    september: '9월',
    october: '10월',
    november: '11월',
    december: '12월',

    // Time formats
    timeFormatSlashesDDMMYYYY: 'DD/MM/YYYY',
    timeFormatSlashesMMDDYYYY: 'MM/DD/YYYY',
    timeFormatSlashesDDMMYY: 'DD/MM/YY',
    timeFormatSlashesMMDDYY: 'MM/DD/YY',
    timeFormatDotsDDMYY: 'DD.M.YY',
    timeFormatDotsMDDYY: 'M.DD.YY',
    timeFormatDashesYYYYMMDD: 'YYYY-MM-DD',
    timeFormatSpacesDDMMMMYYYY: 'DD MMMM YYYY',
    timeFormatHHMMSS: 'HH:MM:SS',
    timeFormatHHMMSSAmPm: 'HH:MM:SS AM/PM',
}
