import React, { useEffect, useState } from 'react'
import { useAxios } from 'api/Axios'
import { useSession } from 'api/Session'
import { SelectObjectType } from 'types/select'
import { ColDef } from 'ag-grid-community'
import { CommDiv, BtnDiv, Container } from './Home.styles'
import { useAlert } from 'components/Elements/Alert/Alert'
import { DataType } from 'types/home.type'
import { CommCodesType } from 'types/commCodes.type'
import { useNavigate } from 'react-router-dom'

import Grid from 'components/Grid/Grid'
import Header from 'components/Elements/Header/Header'
import ACheckSelector from 'components/Elements/ACheckSelector/ACheckSelector'
import ARangeSelector from 'components/Elements/ARangeSelector/ARangeSelector'
import AButton from 'components/Elements/AButton/AButton'

const mapCommCodeToSelectOptions = (
    codeList: CommCodesType[],
): SelectObjectType[] =>
    codeList.map(item => ({
        text: item.cdNm,
        value: item.dtlCd,
    }))

const COMM_CODE_TYPES = [
    'PR001',
    'PR002',
    'PR003',
    'PR004',
    'PR005',
    'PR006',
    'PR007',
    'PR008',
] as const

function Home() {
    const [codeItems, setCodeItems] = useState<
        Record<string, SelectObjectType[]>
    >({})
    const [rowData, setRowData] = useState<DataType[]>([])
    const [selectedItem, setSelectedItem] = useState<DataType[]>([])
    const [filteredData, setFilteredData] = useState<DataType[]>([])
    const [filters, setFilters] = useState<Record<string, string[]>>({})
    const [searchText, setSearchText] = useState('')
    const [rangeFilter, setRangeFilter] = useState({
        deposit: { min: 0, max: 99999 },
        monthlyRent: { min: 0, max: 99999 },
    })

    const { get, post } = useAxios()
    const { open } = useAlert({
        icon: 'error',
        title: '알림',
        text: '선택된 매물이 없습니다.',
    })
    const { open: successUpdate } = useAlert({
        icon: 'success',
        title: '알림',
        text: '최신일자 변경이 완료되었습니다.',
    })
    const { open: successDelete } = useAlert({
        icon: 'success',
        title: '알림',
        text: '삭제가 완료되었습니다.',
    })
    const { setSession, getSession } = useSession()

    const navigate = useNavigate()

    const selectProduct = async () => {
        const res = await get<DataType>('/api/product/products')

        if (res.valid && res.result) {
            setRowData(res.result.LIST)
        }
    }

    useEffect(() => {
        async function getCommCode() {
            const response = await get<CommCodesType>(
                '/api/comm/code/commCodes',
            )
            selectProduct()

            if (response.valid && response.result) {
                setSession('commCodes', response.result.LIST)

                const processedCodeItems = COMM_CODE_TYPES.reduce(
                    (acc, codeType) => {
                        acc[codeType] = mapCommCodeToSelectOptions(
                            getSession('commCodes')[codeType].slice(1),
                        )
                        return acc
                    },
                    {} as Record<string, SelectObjectType[]>,
                )

                setCodeItems(processedCodeItems)
            }
        }
        getCommCode()
    }, [])

    const [headers] = useState<ColDef[]>([
        {
            field: 'prodNo',
            // flex: 1,
            // minWidth: 100,
            headerName: '매물번호',
            width: 100,
        },
        {
            field: 'title',
            // flex: 1,
            // minWidth: 100,
            headerName: '매물명',
            width: 150,
        },
        {
            field: 'depositAndMonthlyRent',
            // flex: 1,
            // minWidth: 100,
            headerName: '금액',
            width: 270,
            valueGetter: params => {
                const tradeType = params.data.tradeType
                const item = params.data

                if (['10', '20', '30', '40'].includes(tradeType)) {
                    return item.depositAndMonthlyRent
                } else if (tradeType === '50') {
                    return item.salePricdAndDepoAndRent
                } else if (tradeType === '60') {
                    return item.depositAndMonthlyRentAndPreFee
                }

                return ''
            },
        },
        {
            field: 'entrancePwd',
            // flex: 1,
            // minWidth: 130,
            headerName: '비밀번호(세대)',
            width: 120,
        },
        {
            field: 'unitPwd',
            // flex: 1,
            // minWidth: 130,
            headerName: '비밀번호(현관)',
            width: 120,
        },
        {
            field: 'prodAddr',
            // flex: 1,
            // minWidth: 100,
            headerName: '주소',
            width: 350,
        },
        {
            field: 'phoneNo1',
            // flex: 1,
            // minWidth: 100,
            headerName: '연락처1',
            width: 160,
            valueFormatter: params => {
                const phoneNumber = params.value
                if (!phoneNumber) return ''

                const cleaned = phoneNumber.replace(/[^\d]/g, '')

                if (cleaned.length === 10) {
                    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
                } else if (cleaned.length === 11) {
                    return cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
                }

                return phoneNumber
            },
        },
        {
            field: 'phoneNo2',
            // flex: 1,
            // minWidth: 100,
            headerName: '연락처2',
            width: 160,
            valueFormatter: params => {
                const phoneNumber = params.value
                if (!phoneNumber) return ''

                const cleaned = phoneNumber.replace(/[^\d]/g, '')

                if (cleaned.length === 10) {
                    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
                } else if (cleaned.length === 11) {
                    return cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
                }

                return phoneNumber
            },
        },
        {
            field: 'etc',
            // flex: 1,
            // minWidth: 100,
            headerName: '기타정보',
            width: 340,
        },
    ])

    const COMM_CODE_MAP: Record<string, keyof DataType> = {
        PR001: 'type',
        PR002: 'tradeType',
        PR003: 'roomCd',
        PR004: 'bathCd',
        PR005: 'directionCd',
        PR006: 'moveInCd',
        PR007: 'rcmCd',
        PR008: 'remarkCd',
    }

    useEffect(() => {
        const filteredData = rowData.filter(item => {
            const deposit = item.deposit ? Number(item.deposit) : 0
            const rent = item.monthlyRent ? Number(item.monthlyRent) : 0

            const isWithinDepositRange =
                deposit >= rangeFilter.deposit.min &&
                deposit <= rangeFilter.deposit.max

            const isWithinRentRange =
                rent >= rangeFilter.monthlyRent.min &&
                rent <= rangeFilter.monthlyRent.max

            const matchesSearchText = searchText
                ? Object.values(item).some(value =>
                      value
                          ?.toString()
                          .toLowerCase()
                          .includes(searchText.toLowerCase()),
                  )
                : true

            return (
                Object.entries(filters).every(([key, selectedValues]) => {
                    const mappedKey = COMM_CODE_MAP[key]
                    const itemValue = item[mappedKey]

                    if (key === 'PR008') {
                        return (
                            selectedValues.length === 0 ||
                            selectedValues.some(value =>
                                itemValue.includes(value),
                            )
                        )
                    }
                    return (
                        selectedValues.length === 0 ||
                        selectedValues.includes(itemValue)
                    )
                }) &&
                isWithinDepositRange &&
                isWithinRentRange &&
                matchesSearchText
            )
        })

        setFilteredData(filteredData)
    }, [filters, rangeFilter, rowData, searchText])

    const handleChangeSelector = (key: string, selectedItems: string[]) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [key]: selectedItems,
        }))
    }

    const handleRangeChange = (
        type: 'deposit' | 'monthlyRent',
        range: { min: number; max: number },
    ) => {
        setRangeFilter(prev => ({
            ...prev,
            [type]: range,
        }))
    }

    const handleSelectionCheckBox = (item: unknown[]) => {
        const row = item as DataType[]

        setSelectedItem(row)
    }

    const handleRowClick = (item: unknown) => {
        const row = item as DataType

        if (row.prodAddr) {
            navigate('/map', {
                state: { item: row.prodAddr },
            })
        }
    }

    const handleCrrection = () => {
        if (selectedItem.length === 0) {
            open()
            return
        }
        const itemToUpdate = { ...selectedItem[0], chgResn: 'U' }

        switch (selectedItem[0].tradeType) {
            case '10':
            case '20':
            case '30':
            case '40':
                navigate('/product/registration', {
                    state: { item: itemToUpdate },
                })
                break
            case '50':
                navigate('/bargain/registration', {
                    state: { item: itemToUpdate },
                })
                break
            case '60':
                navigate('/rent/registration', {
                    state: { item: itemToUpdate },
                })
                break
        }
    }

    const handleCopy = () => {
        if (selectedItem.length === 0) {
            open()
            return
        }

        const itemToCopy = { ...selectedItem[0], chgResn: 'C' }

        switch (selectedItem[0].type) {
            case '10':
            case '20':
            case '30':
            case '40':
                navigate('/product/registration', {
                    state: { item: itemToCopy },
                })
                break
            case '50':
                navigate('/bargain/registration', {
                    state: { item: itemToCopy },
                })
                break
            case '60':
                navigate('/rent/registration', {
                    state: { item: itemToCopy },
                })
                break
        }
    }

    const handleUpdateNewDtm = async () => {
        if (selectedItem.length === 0) {
            open()
            return
        }

        const response = await post<void>('/api/product/updateNewDtm', {
            prodNo: selectedItem[0].prodNo,
            userNo: getSession('userNo'),
        })

        if (response.valid && response.result) {
            successUpdate(() => {
                selectProduct()
            })
        }
    }

    const handleDeleteProd = async () => {
        if (selectedItem.length === 0) {
            open()
            return
        }

        const response = await post<void>('/api/product/deleteProduct', {
            prodNo: selectedItem[0].prodNo,
        })

        if (response.valid && response.result) {
            successDelete(() => {
                selectProduct()
            })
        }
    }

    const handleInputChange = (value: string) => {
        setSearchText(value)
    }

    return (
        <>
            <Header handleInputChange={handleInputChange} />
            <Container>
                <CommDiv>
                    <ARangeSelector
                        onChange={range => handleRangeChange('deposit', range)}
                        min={0}
                        max={99999}
                        placeholder="보증금"
                        mr="10px"
                    />
                    <ARangeSelector
                        onChange={range =>
                            handleRangeChange('monthlyRent', range)
                        }
                        min={0}
                        max={99999}
                        placeholder="월세"
                        mr="10px"
                    />
                    {COMM_CODE_TYPES.map((codeType, index) => {
                        const placeholderMap = {
                            PR001: '거래종류',
                            PR002: '거래방식',
                            PR003: '방수',
                            PR004: '욕실수',
                            PR005: '방향',
                            PR006: '입주일',
                            PR007: '상태',
                            PR008: '추가옵션',
                        }

                        return (
                            <ACheckSelector
                                key={codeType}
                                options={codeItems[codeType] || []}
                                placeholder={placeholderMap[codeType]}
                                onChange={selectedItems =>
                                    handleChangeSelector(
                                        codeType,
                                        selectedItems,
                                    )
                                } // Key 전달
                                mr="10px"
                            />
                        )
                    })}
                </CommDiv>
                <BtnDiv>
                    <AButton onClick={handleCrrection} width="60px" mr="8px">
                        수정
                    </AButton>
                    <AButton onClick={handleCopy} width="60px" mr="8px">
                        복사
                    </AButton>
                    <AButton onClick={handleUpdateNewDtm} width="60px" mr="8px">
                        UP
                    </AButton>
                    <AButton onClick={handleDeleteProd} width="60px">
                        삭제
                    </AButton>
                </BtnDiv>
                <Grid
                    onRowClick={handleRowClick}
                    onselectionchange={handleSelectionCheckBox}
                    items={filteredData}
                    columns={headers}
                />
            </Container>
        </>
    )
}

export default Home
