import AInput from 'components/Elements/AInput/AInput'
import AButton from 'components/Elements/AButton/AButton'
import logo from 'assets/img/logo.png'
import logo2 from 'assets/img/bangdoriLogo.png'

import {
    Wrapper,
    Logo,
    Form,
    LeftSection,
    RightSection,
    Img,
    LoginBtnDiv,
} from './Login.styles'
import { useState } from 'react'
import { useAxios } from 'api/Axios'
import { useAlert } from 'components/Elements/Alert/Alert'
import { useSession } from 'api/Session'
import { useNavigate } from 'react-router-dom'

interface LoginProps {
    userNo: number
    userId: string
    username: string
    token: string
}

const Login = () => {
    const [id, setId] = useState('')
    const [password, setPassword] = useState('')

    const { post } = useAxios()
    const { setSession } = useSession()
    const navigate = useNavigate()

    const { open } = useAlert({
        icon: 'error',
        title: '알림',
        text: '아이디와 비밀번호를 다시 확인해주세요.',
    })

    const { open: success } = useAlert({
        icon: 'success',
        title: '알림',
        text: '로그인 되었습니다.',
    })

    const handleIdChange = (value: string) => {
        setId(value)
    }

    const handlePasswordChange = (value: string) => {
        setPassword(value)
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const response = await post<LoginProps>(
            '/api/user/login',
            {
                id: id,
                password: password,
            },
            true,
        )

        if (response.valid && response.result?.token) {
            setSession('auth-token', response.result.token)
            setSession('username', response.result.username)
            setSession('userId', response.result.userId)
            setSession('userNo', response.result.userNo)

            success(() => {
                navigate('/home')
            })
        } else {
            open()
            return
        }
    }

    return (
        <Wrapper>
            <Form onSubmit={handleSubmit}>
                <LeftSection>
                    <Logo src={logo2} alt="logo" />
                    <AInput
                        onChange={handleIdChange}
                        label="아이디"
                        type="text"
                        iconType="user"
                        width="100%"
                        placeholder="아이디를 입력 해주세요."
                    />
                    <AInput
                        onChange={handlePasswordChange}
                        label="비밀번호"
                        type="password"
                        iconType="password"
                        width="100%"
                        placeholder="비밀번호를 입력 해주세요."
                    />
                    <LoginBtnDiv>
                        <AButton type="submit">로그인</AButton>
                    </LoginBtnDiv>
                </LeftSection>
                <RightSection>
                    <Img src={logo} alt="logo" />
                </RightSection>
            </Form>
        </Wrapper>
    )
}

export default Login
